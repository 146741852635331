<template>
  <header-stats />
</template>

<script>
import HeaderStats from "../components/Headers/HeaderStats.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    HeaderStats,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
